import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SteeringRoseOptions } from './types';
import { css } from 'emotion';
import { stylesFactory, Switch } from '@grafana/ui';
import * as d3 from 'd3';

interface Props extends PanelProps<SteeringRoseOptions> {}

interface SteeringRoseState {
  wellName: string;
  mtfOrGtf: 'MTF' | 'GTF';
}

export const SteeringRosePanel: React.FC<Props> = ({ options, data, width, height }) => {
  // const theme = useTheme();
  const styles = getStyles();
  const widthSvg = width;
  const heightSvg = height * 0.7;
  const cx = widthSvg / 2;
  const cy = heightSvg / 2;
  const numberOfInnerCircles = 6;
  const color = 'white';
  const margin = Math.min(cx, cy) * 0.2;
  const r = Math.min(cx, cy) - margin;
  const numberOfDashes = 120;
  const dash = (Math.PI * r) / numberOfDashes;
  // const bgcolor = 'steelblue';

  const [state, setState] = useState<SteeringRoseState>({ wellName: 'testwell', mtfOrGtf: 'MTF' });

  const innerCircles = (i: number) => {
    let r2 = (r * i) / numberOfInnerCircles;

    return <circle r={r2} strokeDasharray={dash} />;
  };

  // let x = d3.scaleLinear().domain([-180, 180]);
  let xAxis = (g: any) => {
    g.selectAll('*').remove();

    return g.attr('font-size', r / 10).call((g: any) =>
      g
        .selectAll('g')
        .data(d3.range(-180, 179, 30))
        .join('g')
        .call((g: any) =>
          g.append('path').attr(
            'd',
            (d: any) => `
            M${[0, 0]}
            L${d3.pointRadial((d * Math.PI) / 180, r - r / numberOfInnerCircles)}
          `
          )
        )
        .attr('stroke-dasharray', dash)
        .call((g: any) =>
          g
            .append('path')
            .attr('stroke-dasharray', 'none')
            .attr(
              'd',
              (d: any) => `
            M${d3.pointRadial((d * Math.PI) / 180, r)}
            L${d3.pointRadial((d * Math.PI) / 180, r + r / numberOfInnerCircles - r / 10)}
          `
            )
        )

        .call((g: any) =>
          g
            .append('text')
            .attr('stroke-dasharray', 'none')
            .attr('text-anchor', 'middle')
            .attr('x', (d: any) => d3.pointRadial((d * Math.PI) / 180, r + r / numberOfInnerCircles)[0])
            .attr('y', (d: any) => d3.pointRadial((d * Math.PI) / 180, r + r / numberOfInnerCircles)[1])
            // .attr("xlink:href", d => d.id.href)
            .text((d: any) => d)
        )
    );
  };

  useEffect(() => {
    d3.select('#xAxis').call(xAxis);
  }, [widthSvg, heightSvg]);

  useEffect(() => {
    if (data.series.length > 0) {
      setState({ ...state, wellName: data.series[0].name ?? '' });
    }
  }, [data]);
  return (
    <div
      style={{
        color,
        fontSize: r / 10,
        background: 'linear-gradient(169deg, rgba(140,140,143,1) 1%, rgba(35,35,36,1) 95%)',

        border: undefined,
        width,
        height,
        position: 'absolute',
      }}
    >
      <div
        style={{
          position: 'absolute',
          border: undefined,
          height: (height - heightSvg) / 2,
          width,
          display: 'flex',
        }}
      >
        <div style={{ flex: 1, padding: '1em' }}>
          <div>MTF:</div>
          <div>
            {/* <Switch value={state.mtfOrGtf === 'MTF'}></Switch> */}
            <Switch></Switch>
          </div>
          <div>GTF:</div>
        </div>
        <div className={styles.col}>
          <div>Well Name: </div>
          <div className={styles.fieldVal}>{state.wellName}</div>
          <div>Latitude:</div>
          <div className={styles.fieldVal}></div>
          <div>Longitude:</div>
          <div className={styles.fieldVal}></div>
        </div>
      </div>
      <div style={{ top: (height - heightSvg) / 2, height: (height - heightSvg) / 2, position: 'absolute' }}>
        <svg
          id="chart"
          height={heightSvg}
          width={widthSvg}
          stroke={color}
          strokeWidth="2"
          viewBox={`${-widthSvg / 2} ${-heightSvg / 2} ${widthSvg} ${heightSvg}`}
        >
          <circle r={r} fill="none" />
          <g fill="none">${d3.range(numberOfInnerCircles).map(innerCircles)}</g>
          <g fill="none">
            <circle r={r / 40} fill={color} />
          </g>
          <g id="xAxis"></g>
        </svg>
      </div>
      <div
        style={{
          display: 'flex',

          border: undefined,
          height: (height - heightSvg) / 2,
          top: heightSvg + (height - heightSvg) / 2,
          width,
          position: 'absolute',
        }}
      >
        <div className={styles.col}>
          <div>Clock:</div>
          <div className={styles.fieldVal}></div>
          <div>North Ref:</div>
          <div className={styles.fieldVal}></div>
        </div>
        <div className={styles.col}>
          <div>TFC:</div>
          <div className={styles.fieldVal}></div>
          <div>Total Correction:</div>
          <div className={styles.fieldVal}></div>
        </div>
      </div>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    col: css`
      flex: 1;
      padding: 1em;
      display: grid;
      grid-template-columns: auto auto;
    `,
    fieldVal: css`
      color: #e9967a;
      font-weight: bold;
    `,
    // grid: css`
    //   display: grid;
    //   grid-template-columns: auto auto;
    // `,
  };
});
