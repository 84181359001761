import { PanelPlugin } from '@grafana/data';
import { SteeringRoseOptions } from './types';
import { SteeringRosePanel } from './SteeringRosePanel';

export const plugin = new PanelPlugin<SteeringRoseOptions>(SteeringRosePanel).setPanelOptions((builder) => {
  return builder;
  // return builder
  //   .addTextInput({
  //     path: 'text',
  //     name: 'Simple text option',
  //     description: 'Description of panel option',
  //     defaultValue: 'Default value of text input option',
  //   })
  //   .addBooleanSwitch({
  //     path: 'showSeriesCount',
  //     name: 'Show series counter',
  //     defaultValue: false,
  //   })
  //   .addRadio({
  //     path: 'seriesCountSize',
  //     defaultValue: 'sm',
  //     name: 'Series counter size',
  //     settings: {
  //       options: [
  //         {
  //           value: 'sm',
  //           label: 'Small',
  //         },
  //         {
  //           value: 'md',
  //           label: 'Medium',
  //         },
  //         {
  //           value: 'lg',
  //           label: 'Large',
  //         },
  //       ],
  //     },
  //     showIf: (config) => config.showSeriesCount,
  //   });
});
